<template>
  <div class="text-center pt-12">
    <h1 class="font-condensed font-bold md:text-headline text-5xl text-black">WÄHLE DEIN ABO!</h1>
    <p class="text-lg md:text-subline px-4 md:px-32 font-sans text-center font-thin py-3">
      Laufzeit je Abo sind immer 12 Monate. Jedes Abo kann einzeln oder einzeln zu einem beliebigen späteren Zeitpunkt
      dazu gebucht werden.
    </p>
  </div>
  <div class="flex justify-center pt-12">
    <div>
      <el-switch
          v-model="basketStore.periodSwitcher"
          active-text="Monatlich"
          inactive-text="Jährlich"
          class="period-switcher"
      ></el-switch>
      <div class="flex pl-5">
        <img :src="require('@/assets/icons/pricing/arrow_up.svg')" alt="" class="h-7 pt-3">
        <p class="text-bm-primary font-bold pt-4 pl-1">Spare bis zu 13%</p>
      </div>
    </div>
  </div>
  <div class="grid grid-col-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-12 px-4 xl:px-0 md:pt-16 pb-5" id="subs">
    <div class="border-2 border-bm-gray-1 rounded px-4 pt-6 pb-3" v-for="(sub, index) in subStore.subs"
         :key="sub.id">
      <div>
        <div class="flex justify-center">
          <img :src="sub.icon" :alt="sub.title" class="h-28" v-if="sub.icon">
        </div>
        <p class="text-center text-black text-lg">Abo {{ index + 1 }}</p>
        <p class="uppercase text-4xl font-condensed font-bold text-center text-black mb-4">{{
            sub.title
          }}</p>
        <hr class="border-1 border-fmu-gray-1">
        <div v-if="!sub.is_free && !basketStore.periodSwitcher" class="pt-5 pb-3">
          <div class="flex justify-center">
            <p class="pt-2.5 line-through pr-1 text font-thin">{{ formatCurrency(sub.price_monthly) }}</p>
            <p class="text-3xl font-bold text-bm-primary">{{ formatCurrency(sub.price_yearly / 12) }}</p>
            <p class="pt-2.5 pl-1 text-bm-primary">/ Monat</p>
          </div>
          <div class="text-center font-thin">bei jährlicher Zahlung</div>
          <div class="text-center font-thin pt-2">
            <span class="line-through px-1">{{ formatCurrency((sub.price_monthly * 12)) }}</span>
            <span class="font-semibold">{{ formatCurrency((sub.price_yearly)) }}</span>
            <span> / Jahr</span>
          </div>
        </div>
        <div v-if="!sub.is_free && basketStore.periodSwitcher" class="pt-6 pb-5 relative">
          <div class="flex justify-center">
            <p class="text-3xl font-bold text-bm-primary">{{ formatCurrency(sub.price_monthly) }}</p>
            <p class="pt-2.5 pl-1 font-thin">/ Monat</p>
          </div>
          <div class="text-center">bei monatlicher Zahlung</div>
          <div class="text-center font-thin pt-2">
            <span class="font-semibold">{{ formatCurrency((sub.price_monthly * 12)) }}</span>
            <span> / Jahr</span>
          </div>
        </div>
      </div>
      <div class="flex justify-center" v-if="!basketStore.periodSwitcher ">
        <div class="text-bm-primary font-bold px-2 rounded-xl" style="background: #EFF1F1">
          {{ subStore.getPercentageDiscount(sub) }}% sparen
        </div>
      </div>
      <div @click="basketStore.addToBasket(sub)" class="pt-8" v-if="sub.id !== 2">
        <div class="flex justify-center">
          <fmu-button class="mx-auto inset-x-0"
                      button-type="secondary"
                      v-if="subAlreadyBooked(sub)" disabled>Bereits
            abonniert
          </fmu-button>
        </div>
        <div class="flex justify-center">
          <fmu-button class="mx-auto inset-x-0" disabled
                      button-type="secondary"
                      v-if="subIsInBasket(sub)">Bereits im
            Warenkorb
          </fmu-button>
        </div>
        <div class="flex justify-center">
          <fmu-button class="mx-auto inset-x-0" button-type="primary" v-if="subCanBeAddedToBasket(sub)">In den
            Warenkorb
          </fmu-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FmuButton from "@/components/theme/button/FmuButton";
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {useUserStore} from "@/store/user/user-store";
import {useSubStore} from "@/store/subs/sub-store";
import {onMounted} from "vue"
import {useBasketStore} from "@/store/basket/basket-store";
import {ElSwitch} from "element-plus";
import {formatCurrency} from "@/filter/currency";

export default {
  name: 'PricingCards',
  components: {FmuButton, ElSwitch},
  setup() {
    const newAboModalStore = useNewAboModalStore();
    const basketStore = useBasketStore();
    const userStore = useUserStore();
    const subStore = useSubStore();

    onMounted(async () => {
      await subStore.getSubOptions();
      await subStore.getSubs();
    })

    const subAlreadyBooked = (sub) => {
      return userStore.user.isLoggedIn && userStore.user.fmu_subscription_ids?.includes(sub.id)
    }

    const subIsInBasket = (sub) => {
      return !userStore.user.fmu_subscription_ids?.includes(sub.id) && basketStore.ids.includes(sub.id)
    }

    const subCanBeAddedToBasket = (sub) => {
      return !basketStore.ids.includes(sub.id) && !userStore.user.fmu_subscription_ids?.includes(sub.id)
    }

    return {
      subCanBeAddedToBasket,
      subIsInBasket,
      subAlreadyBooked,
      formatCurrency,
      basketStore,
      newAboModalStore,
      subStore,
      userStore,
    }
  }
}
</script>

<style scoped>
.skew::before {
  content: "";
  border-left: #505050 solid thin;
  transform: skew(-20deg);
  display: inline-block;
  height: 3rem;
  float: left
}
</style>
<style>
.period-switcher > .el-switch__label > span {
  font-size: 16pt !important;
  font-weight: 700 !important;
  color: #000;
}
</style>
