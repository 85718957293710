<template>
  <div style="background: #F1F5F5" class="py-16">
    <h1 class="text-headline font-condensed font-bold text-black text-center uppercase">Was sagen unsere Kunden?</h1>
    <div class="md:flex justify-center px-10 md:px-0 pt-10 md:pt-5">
      <div>
        <img :src="require('@/assets/images/pricing/testimonial.svg')" alt="Das sagen unsere Kunden"
             class="text-center hidden md:block" style="min-height: 500px">
        <img :src="require('@/assets/images/pricing/testimonial_mobil.svg')" alt="Das sagen unsere Kunden"
             class="text-center md:hidden">

        <div class="flex justify-center">
          <div>
            <img :src="require('@/assets/images/kevin.png')" alt="Kevin Meier" class="h-16 pr-3">
          </div>
          <div class="pt-3">
            <p class="font-condensed font-bold text-black uppercase text-lg">Kevin Meier</p>
            <p class="text">Modebox, Emmerdingen</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Testimonial',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
