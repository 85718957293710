export const tutorialsText = [
    {
        headline: 'GIF Sticker platzieren',
        description: ' <div class="video-container">\n' +
            '              <iframe class="w-full px-4 md:px-0" style="min-height: 600px" src="https://www.youtube.com/embed/-6kzd2H-8VE"></iframe>\n' +
            '            </div>'
    },
    {
        headline: 'Reels Anleitung',
        description: '<div class="video-container">\n' +
            '              <iframe class="w-full h-84 px-4 md:px-0" style="min-height: 600px" src="https://www.youtube.com/embed/zjE0ljjLKmA"></iframe>\n' +
            '            </div>\n' +
            '            <br>\n' +
            '            <div>\n' +
            '              1. Lade Dein gewünschtes Reel aus der BasicMarketing.Cloud herunter. <br><br>\n' +
            '              2. Gehe in Deine Instagram-App, klicke oben rechts in der App auf das Plus-Symbol und wähle dann weiter unten die Funktion Reel aus.<br>\n' +
            '              <br>\n' +
            '              3. Es wird sich direkt Deine Smartphone-Kamera öffnen. Über die kleine Bildvorschau unten links kannst Du bereits fertige Clips von Deinem Smartphone auswählen und hochladen.\n' +
            '              <br><br>\n' +
            '              4. Nach dem Hochladen des Clips gehst Du wieder in den Aufnahme-Modus und klickst unten rechts auf „Vorschau“.\n' +
            '              <br><br>\n' +
            '              5. In der Vorschau kannst Du Dir den Clip noch einmal anschauen, Sticker oder Gifs platzieren oder weiteren Text einfügen.\n' +
            '              <br><br>\n' +
            '              6. Unten rechts findest Du einen kleinen Pfeil, über den Du zur Bildbeschreibung gelangst. Dort kannst Du im Textfeld Deinen Beitragstext einfügen und unterhalb des Textfeldes Personen markieren.\n' +
            '              <br><br>\n' +
            '              7. Du siehst oben links neben dem Textfeld eine kleine Vorschau des Reels und ein vom Clip generiertes Titelbild. Um das Titelbild zu ändern, klickst Du auf die Vorschau und im nächsten Fenster unten auf „Aus Aufnahmen hinzufügen“ und wählst dort das Startbildbild, das Du aus der BasicMarketing.Cloud gemeinsam mit dem Clip heruntergeladen hast.\n' +
            '              <br><br>\n' +
            '              8. Klicke dann auf Teilen und Dein Reel wird in Deinem Feed angezeigt.\n' +
            '              <br><br>\n' +
            '              Viel Spaß!\n' +
            '            </div>'
    },
    {
        headline: 'Story Anleitung',
        description: '<p class="font-bold underline">Story aus Deinem Post heraus</p>\n' +
            '            <p class="font-bold">Achtung: Auf diese Weise gibt es EINE VERLINKUNG zwischen Deiner Story und Deinem Post! Wir empfehlen diesen Vorgang für den gesamten Content „Aktionen“!</p>\n' +
            '            <br>\n' +
            '            1. Lade passend zu Deiner geplanten Story den Post in Deinem Feed hoch.<br><br>\n' +
            '            2. Klicke unter Deinem Post auf den Pfeil. Es öffnet sich ein Fenster. Klicke darin auf den Satz „Beitrag in Deiner Story posten“.<br><br>\n' +
            '            3. Es öffnet sich die Story-Funktion. Platziere darin den Beitrag, wie Du es wünschst.<br><br>\n' +
            '            4. Ergänze über die Text-Funktion (rechts oben) in der Story den von uns vorgeschlagenen bzw. einen individuellen Text.<br><br>\n' +
            '            5. Platziere ihn im Bild und formatiere ihn nach Deinen Wünschen (Fett, Klassisch, Modern, Neon, Schreibmaschine).<br><br>\n' +
            '            6. Stelle die Story online.<br><br>\n' +
            '            7. Wenn Deine User nun Deine Story anklicken, öffnet sich der Link zu Deinem Beitrag/Post. Der Vorteil: Deine User werden auf den Post gelotst und erhalten dort weitere wichtige Informationen zu Deiner Aktion (Datum, Uhrzeit, Konditionen...).\n' +
            '            <br><br>\n' +
            '            <p class="font-bold underline">Über die Story-Funktion</p>\n' +
            '            <p class="font-bold">Achtung: Auf diese Weise gibt es KEINE VERLINKUNG zwischen Deiner Story und Deinem Post!</p>\n' +
            '            <br>\n' +
            '            1. Lade das downgeloadete Story-Foto in Deine Story-Funktion bei Facebook und Instagram.<br><br>\n' +
            '            2. Ergänze über die Text-Funktion (rechts oben) in der Story den von uns vorgeschlagenen bzw. einen individuellen Text.<br><br>\n' +
            '            3. Platziere den Text im Bild und formatiere ihn nach Deinen Wünschen (Fett, Klassisch, Modern, Neon, Schreibmaschine).<br><br>\n' +
            '            4. Stelle die Story online.<br><br>\n' +
            '            5. Nicht vergessen: Lade passend zu Deiner Story auch den Post in Deinem Feed hoch.'
    },
    {
        headline: 'Trend-Clip Anleitung',
        description: ' <div>\n' +
            '              1. Lade den Trendclip und das dazugehörige Startbild herunter. <br><br>\n' +
            '              2. Speichere beides auf Deinem Smartphone in Deinen Fotos. Lege dafür am besten einen eigenen Foto-Ordner z.B. mit dem Namen „Trendclips“ an.<br><br>\n' +
            '              3. Gehe in Deine Instagram-App und erstelle dort über das Plus-Symbol einen neuen Beitrag in Deinem Feed. Als erstes wählst Du hierzu den ' +
            '                 Clip aus Deinem Foto-Ordner aus, dann klickst Du auf das Symbol für „weitere Fotos hinzufügen“ und wählst als Bild Nr. 2 das dazugehörige Startbild aus. ' +
            '                 Dann Text (siehe Textvorlage) ergänzen und alles in Deinem Feed posten.<br><br>\n' +
            '              4. Auf Deiner Instagram-Seite erscheint nun der Trendclip und durch einen Wischer nach rechts das Startbild.<br><br>\n' +
            '              5. Um eine zusätzliche Reichweite mit Deinen Trendclips zu generieren, empfehlen wir, eine Verlinkung Deines Posts als Story zu erstellen.<br><br>\n' +
            '              6. Hierzu einfach das Startbild auswählen und per Story-Pfeil als Story aufbereiten. Headline (siehe Textvorlagen) und Gif-Sticker „NEW CLIP“ hinzufügen und veröffentlichen.<br><br>\n' +
            '              7. Deine Kunden bekommen nun den Trendclip sowohl im Feed als auch bei den Stories angezeigt. Wer die Story sieht und darauf klickt, landet direkt auf Deinem Post.<br><br>\n' +
            '              Viel Spaß!\n' +
            '            </div>'
    },
    {
        headline: 'Webshop Slider zuschneiden',
        description: '            <div class="video-container">\n' +
            '              <iframe class="w-full h-84 px-4 md:px-0" style="min-height: 600px" src="https://www.youtube.com/embed/eXA9jujXa20"></iframe>\n' +
            '            </div>'
    },
]
