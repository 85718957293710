<template>
  <div class="chooseModal">
    <el-dialog
        title=""
        v-model="changeAboModalStore.isOpen"
        width="700px"
        top="25vh"
        :fullscreen="widthIs('<', 700)"
        custom-class="relative">
      <p class="modal-headline text-center px-24 font-semibold break-normal">
        Du möchtest Dein Abo erweitern?
      </p>
      <br>
      <p class="text-center px-24 break-normal" style="hyphens: auto">
        Du hast bereits ein Abo abgeschlossen, aber möchtest ein weiteres dazu buchen? Kein Problem. Klicke einfach auf die
        Rubrik „Abo & Pricing“ und buche Dein Abo ganz bequem um.
      </p>
      <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
        <a href="/abo-pricing#subs">
          <fmu-button button-type="outline">Weiter zu Abo & Pricing</fmu-button>
        </a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {ElDialog} from 'element-plus'
import {useChangeAboModalStore} from "@/store/modals/change-abo-modal-store";
import FmuButton from "@/components/theme/button/FmuButton";
import widthIs from "@/validators/validators";

export default {
  name: 'ChangeSubModal',
  components: {
    ElDialog,
    FmuButton
  },
  setup() {
    const changeAboModalStore = useChangeAboModalStore();
    return {
      changeAboModalStore,
      widthIs
    }
  }
}
</script>

<style>
.chooseModal > .el-overlay > .el-dialog {
  height: 340px;
}
</style>
<style scoped>
</style>
