export const faqText = [
    {
        headline: 'Wie lange gilt ein Abo?',
        description: 'Laufzeit je Abo sind immer 12 Monate. Jedes Abo kann einzeln oder einzeln zu einem beliebigen späteren Zeitpunkt dazu gebucht werden.'
    },
    {
        headline: 'Wie erfolgt die Abrechnung des Abos?',
        description: 'Die Abrechnung erfolgt je nach Wunsch monatlich oder jährlich und gemäß Ihrer gewählten Zahlungsart via Unitex-Zentralregulierung oder auf Rechnung. ' +
            'Ihre Zugangsdaten erhalten Sie schnellstmöglich nach Bestellabschluss und Ihr Abo können Sie direkt nach Erhalt der Zugangsdaten nutzen. ' +
            'Bis zum 1. des Folgemonats ist das Abo GRATIS. Zum 1. des Folgemonats beginnt die Vertragslaufzeit und zum 5. ' +
            'des darauffolgenden Monats erfolgt die Rechnungsstellung.'
    },
    {
        headline: 'Wie und wie oft darf ich den Content verwenden?',
        description: '              Je nachdem welches Abo Du gebucht hast, darfst du den Content für folgende Kanäle verwenden: für Deine\n' +
            '              Social Media (bei Abo 1 und Abo 2), für Deine App und Dein Webshop (bei Abo 3) und für Print-Postkarten\n' +
            '              (bei Abo 4). Pro Kanal darfst Du beliebig viel Content beliebig oft verwenden, hier gibt es keinerlei\n' +
            '              Limitierung.'
    },
    {
        headline: 'Darf ich den Content für mehrere Filialen meines Unternehmens nutzen?',
        description: 'Ja, die Nutzung des Contents ist pro Unternehmen für bis zu 10 Filialen und 10 Mio. € Umsatz im Jahr\n' +
            '              gestattet.'
    },
    {
        headline: 'Darf ich den Content an Dritte weitergeben?',
        description: 'Nein, eine Weitergabe des Contents an Dritte (z.B. andere Händler, Firmen, Personen) ist ausdrücklich\n' +
            '              untersagt. Missbrauch wird mit einer Vertragsstrafe von 5.000 € je Fall geahndet.'
    },
    {
        headline: 'Darf ich den Content für eine Tochter- oder Schwesterfirmen verwenden?',
        description: 'Nein, die Nutzung des Contents gilt nur für das abonnierte Unternehmen und nicht für eventuelle Tochter-\n' +
            '              und Schwesterfirmen. Missbrauch wird mit einer Vertragsstrafe von 5.000 € je Fall geahndet. Jedoch\n' +
            '              gestattet ist die Nutzung des Contents pro Unternehmen für bis zu 10 Filialen und 10 Mio. € Umsatz im\n' +
            '              Jahr.'
    },
    {
        headline: 'Kann ich den Content direkt vom Smartphone aus downloaden?',
        description: 'Ja, der Content lässt sich auf allen Endgeräten downloaden – egal ob PC, Notebook, Tablet oder Smartphone.'
    },
    {
        headline: 'Was passiert bei schlechter Internetverbindung?',
        description: 'Wenn die Internetverbindung zu langsam ist, ist mit langen Ladezeiten zu rechnen. Schlimmstenfalls kann\n' +
            '              der Download nicht erfolgen. Stelle für den Download daher bitte immer sicher, dass Du eine gute\n' +
            '              Internetverbindung hast.'
    },
    {
        headline: 'Wie erfolgt die Kündigung des Abos?',
        description: 'Laufzeit je Abo sind immer 12 Monate. Eine Kündigung ist drei Monate vor Ablauf möglich und muss in\n' +
            '              Schriftform oder per eMail an info@hutter-unger.de erfolgen.'
    }
]
