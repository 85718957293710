import { defineStore } from 'pinia'

export const useChangeAboModalStore = defineStore({
    id: 'changeAboModal',
    state: () => ({
        isOpen: false,
        form: {
            name: '',
            street: '',
            plz: '',
            city: '',
            email: ''
        },
    }),
    getters: {},
    actions: {
        toggle() {
            this.isOpen = !this.isOpen;
        }
    },
})
