<template>
  <div v-for="(faq, index) in $props.data" :key="faq.headline">
    <div :class="{'bg-black text-white': showAccordionItem[index]}" class="cursor-pointer text-black font-bold py-4 px-4 border flex" @click="toggleAccordion(index)">
      <img :src="require('@/assets/icons/chevron_down_white.svg')" alt="" class="h-6 pr-1" v-if="showAccordionItem[index]">
      <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-6 pr-1" v-else>
      <div>
        {{ faq.headline }}
      </div>
    </div>
    <div class="bg-bm-font-gray-4 py-4 px-6 text-bm-font-gray-1 break-normal" v-if="showAccordionItem[index]" v-html="faq.description"></div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: 'Accordion',
  components: {},
  props: ['data'],
  setup(props) {
    const showAccordionItem = ref([])
    // eslint-disable-next-line no-unused-vars
    props.data.forEach((item, i) => {
      let status = i === 0;
      showAccordionItem.value.push(status)
    })

    const toggleAccordion = (index) => {
      let tempArray = [];
      showAccordionItem.value.forEach((item, i) => {
        tempArray.push(i === index);
      })

      showAccordionItem.value = tempArray;
    }

    return {toggleAccordion, showAccordionItem}
  }
}
</script>

<style scoped>
</style>
