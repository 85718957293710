<template>
  <div class="pt-20" id="faq">
    <h1 class="text-center text-5xl md:text-headline font-bold font-condensed text-black">HÄUFIG GESTELLTE FRAGEN</h1>
    <div class="flex justify-center pt-8 pb-20">
      <div class="fmu-container w-full px-4 md:px-20">
        <accordion :data="faqText"></accordion>
<!--        <h1 class="text-headline font-condensed font-bold text-black text-center pb-8 pt-20 uppercase">Tutorials</h1>-->
<!--        <accordion :data="tutorialsText"></accordion>-->
      </div>
    </div>
  </div>
 <!-- <info-box>
      <div class="flex justify-center">
        <div class="fmu-container px-10">
          <h1 class="text-5xl md:text-headline font-condensed font-bold">LIVE-WEBINARE - IDEAL FÜR EINSTEIGER</h1>
          <p class="pt-3">Wir empfehlen unsere 1-stündige Schulung, in der Sie alle Features und Möglichkeiten des Tools genau kennenlernen,
      inkl. Einrichtung des Content-Hubs für Ihre Seiten wie z.B. Facebook, Instagram, Website oder Webshop.</p>
          <router-link to="onlineseminare">
            <fmu-button class="mt-10">Wähle Deine Schulung</fmu-button>
          </router-link>
        </div>
      </div>
  </info-box>
  -->
</template>

<script>
import {faqText} from "../../../services/texts/faq";
import Accordion from "../../../components/accordion/Accordion";
import {tutorialsText} from "../../../services/texts/tutorials";

export default {
  name: 'Faq',
  components: {Accordion},
  setup() {

    return {
      faqText,
      tutorialsText
    }
  }
}
</script>

<style scoped>
</style>
