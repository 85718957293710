<template>
  <div class="flex justify-center">
    <div class="fmu-container">
      <pricing-cards></pricing-cards>
    </div>
  </div>
  <div class="bg-bm-font-gray-4 text-center py-16 mt-20">
    <h1 class="text-5xl md:text-headline font-bold font-condensed text-black mx-10">BIST DU SCHON SMART.KIS NUTZER?</h1>
    <span class="flex justify-center pt-5 px-10">
      <div class="fmu-container">
        <strong>Bitte beachte:</strong>
        Wenn Du noch KEIN Smart.KIS Kunde bei Hutter & Unger bzw. Neukunde bist, buche bitte hier
        Dein Abo und vergib für Dein Konto ein Passwort. Wenn Du bereits Smart.KIS Kunde bist, gib bitte lediglich
        Deine eMail-Adresse an und verwende Dein Smart.KIS Passwort. Das System wird Dich automatisch identifizieren.
      </div>
    </span>
  </div>
  <div class="flex justify-center">
    <div class="fmu-container">
      <package-comparison v-if="subStore.subs.length > 0"></package-comparison>
    </div>
  </div>

  <testimonial></testimonial>

  <faq></faq>

  <change-sub-modal></change-sub-modal>

</template>

<script>
import PricingCards from "./pricing-cards/PricingCards";
import Faq from "./faq/Faq";
import ChangeSubModal from "@/components/modals/change_abo/ChangeSubModal";
import {useUserStore} from "@/store/user/user-store";
import PackageComparison from "./package-comparison/PackageComparison";
import {useSubStore} from "../../store/subs/sub-store";
import Testimonial from "./testimonial/Testimonial";

export default {
  name: 'Home',
  components: {
    Testimonial,
    ChangeSubModal,
    // eslint-disable-next-line vue/no-unused-components
    PackageComparison,
    Faq,
    PricingCards,
  },
  setup() {
    const userStore = useUserStore();
    const subStore = useSubStore();

    return {userStore, subStore}
  }
}
</script>
<style>
</style>
