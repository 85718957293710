<template>
  <div>
    <div class="text-center w-full pt-20 px-10">
      <h1 class="text-5xl md:text-headline text-black font-bold font-condensed ">WELCHES ABO PASST ZU DIR?</h1>
      <p class="text-lg md:text-subline px-4 md:px-32 py-3">
        Im Folgenden beschreiben wir für Dich die spezifischen Vorteile der 4 Abos. Erfahre alles über die
        jeweiligen Inhalte, Features und Nutzungsrechte.
      </p>
    </div>
    <div class="grid grid-col-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-4 xl:px-0 md:pt-0 pb-20">
      <div class="border-2 border-bm-gray-1 rounded mt-14 relative h-auto mx-5 md:mx-0" v-for="(sub, index) in subStore.subs"
           :key="sub.id">
        <div class="px-4 pb-3">
          <div :class="{'md:min-h-600': showFullDescription[index], 'md:min-h-300': !showFullDescription[index]}">
            <p class="text-center pt-8 text-lg">Abo {{ index + 1 }}</p>
            <p class="uppercase text-4xl font-condensed font-bold text-center text-black mb-4">{{
                sub.title
              }}</p>
            <hr class="border-1 border-fmu-gray-1">
            <div class="pt-10 pb-10 md:pb-0 text-sm" style="hyphens:auto">
            <span v-if="!showFullDescription[index]">
              {{ sub.description?.substring(0, 180) }}...
            </span>
              <span v-else>
              {{ sub.description }}
            </span>
              <span class="text-black cursor-pointer font-bold"
                    @click="toggleFullDescription(index)" v-if="!showFullDescription[index]">
                mehr
              </span>
              <span class="text-black cursor-pointer font-bold"
                    @click="toggleFullDescription(index)" v-if="showFullDescription[index]">
                <p>weniger</p>
              </span>
            </div>
          </div>
          <hr class="border-1 border-fmu-gray-1">
          <div class="pt-3">
            <h2 class="font-semibold text-black">Inhalte</h2>
            <div class="pt-3 md:min-h-520">
              <ul>
                <li class="font-thin text-sm pb-1" v-for="benefit in sub.benefits" :key="benefit.benefit">
                  <img :src="require('@/assets/icons/pricing/check.svg')" class="h-4 mt-1 float-left">

                  <p class="font-thin text-sm pb-3 px-8">
                    {{ benefit.benefit }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="showFullComparison[index]">
            <hr class="border-1 border-fmu-gray-1">
            <div class="pt-3 md:min-h-300">
              <h2 class="font-semibold pb-1 text-black">Features</h2>
              <p class="text-sm font-thin" style="white-space: pre-wrap;">
                {{ sub.feature_text }}
              </p>
            </div>
            <hr class="border-1 border-fmu-gray-1">
            <div class="pt-3 min-h-185">
              <h2 class="font-semibold pb-1 text-black">Nutzungsrechte für</h2>
              <p class="text-sm font-thin" style="white-space: pre-wrap;" v-html="sub.use_rights_text">
              </p>
            </div>
            <hr class="border-1 border-fmu-gray-1" v-if="sub.notes_text">
            <div class="pt-3 pb-24" v-if="sub.notes_text">
              <h2 class="font-semibold pb-1 text-black">Hinweise</h2>
              <p class="text-xs font-thin" style="white-space: pre-wrap;">
                {{ sub.notes_text }}
              </p>
            </div>
            <div @click="basketStore.addToBasket(sub)" class="pt-3" v-if="sub.id !== 2">
              <fmu-button class="mx-auto inset-x-0 w-11/12 m-2 absolute bottom-12"
                          v-if="subAlreadyBooked(sub)"
                          button-type="secondary"
                          disabled>
                Bereits abonniert
              </fmu-button>
              <fmu-button class="mx-auto inset-x-0 w-11/12 m-2 absolute bottom-12" disabled
                          button-type="secondary"
                          v-if="subIsInBasket(sub)">
                Bereits im Warenkorb
              </fmu-button>
              <fmu-button class="mx-auto inset-x-0 w-11/12 m-2 absolute bottom-12"
                          v-if="subCanBeAddedToBasket(sub)" button-type="primary">
                In den Warenkorb
              </fmu-button>
            </div>
          </div>
        </div>
        <div class="text-center w-full border-t py-3" :class="{'absolute bottom-0': showFullComparison[index]}">
          <div class="flex justify-center">
            <span class="float-left text-center font-bold text-black cursor-pointer" @click="toggleFullComparison(index)" v-if="!showFullComparison[index]">Mehr Infos laden</span>
            <span class="float-left text-center font-bold text-black cursor-pointer" @click="toggleFullComparison(index)" v-else>Infos ausblenden</span>
            <img :src="require('@/assets/icons/chevron_down.svg')" alt="" class="h-6" v-if="!showFullComparison[index]">
            <img :src="require('@/assets/icons/chevron_up.svg')" alt="" class="h-6" v-else>
          </div>
        </div>
      </div>
      <p class="font-thin text-sm md:w-96 mx-6 md:mx-0">*Alle Preise verstehen sich zzgl. der gesetzlichen Mwst.</p>
    </div>
  </div>
</template>

<script>
import {useSubStore} from "@/store/subs/sub-store";
import {onMounted, ref} from "vue"
import {useMediaFilterStore} from "@/store/media/media-filter-store";
import FmuButton from "@/components/theme/button/FmuButton";
import {useUserStore} from "../../../store/user/user-store";
import {useBasketStore} from "../../../store/basket/basket-store";

export default {
  name: 'PackageComparison',
  components: {FmuButton},
  emits: ['button-click'],
  setup() {
    const subStore = useSubStore();
    const mediaFilterStore = useMediaFilterStore();
    const userStore = useUserStore();
    const basketStore = useBasketStore()

    const maxComparisonItems = ref(0)

    const showFullDescription = ref([]);
    const showFullComparison = ref([]);

    const toggleFullDescription = (index) => {
      showFullDescription.value[index] = !showFullDescription.value[index];
    }

    const toggleFullComparison = (index) => {
      showFullComparison.value[index] = !showFullComparison.value[index];
    }

    subStore.subs.forEach(() => {
      showFullDescription.value.push(false)
      showFullComparison.value.push(false);
    })

    onMounted(async () => {
      await subStore.getSubOptions();
      await subStore.getSubs();
    })

    const subIsInBasket = (sub) => {
      return !userStore.user.fmu_subscription_ids?.includes(sub.id) && basketStore.ids.includes(sub.id)
    }

    const subCanBeAddedToBasket = (sub) => {
      return !basketStore.ids.includes(sub.id) && !userStore.user.fmu_subscription_ids?.includes(sub.id)
    }

    const subAlreadyBooked = (sub) => {
      return userStore.user.isLoggedIn && userStore.user.fmu_subscription_ids?.includes(sub.id)
    }

    return {
      toggleFullComparison,
      showFullDescription,
      showFullComparison,
      subStore,
      mediaFilterStore,
      maxComparisonItems,
      subIsInBasket,
      subCanBeAddedToBasket,
      basketStore,
      subAlreadyBooked,
      toggleFullDescription
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 700px) {
  .bubble-triangle:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid black;
    border-bottom: 12px solid transparent;
    top: 55px;
    left: 140px;
  }
}
</style>
